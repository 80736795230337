@import 'variables';

button {
    &.wide {
        width: 100%;
    }
    &.large {
        font-size: 1.2em;
        font-weight: 400;
        line-height: 2.1em;
        text-transform: uppercase;
    }
}