input {
    background: $color-background;
    border: 0;
    color: white;
    border-radius: $input-border-radius;
    padding: 0.2em;
    line-height: 1.1em;
    margin: 0;
    &.ng-touched.ng-invalid {
        outline: 2px solid $input-outline-color-error;
        outline-offset: 1px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.form-group {
    font-size: 1rem;
    margin-bottom: 1em;
    @media screen and (max-width: $breakpoint-xs) {
        font-size: 0.8rem;
    }
    > * {
        vertical-align: text-bottom;
    }
    label {
        text-align: left;
        font-size: 1em;
        margin-bottom: 0.2em;
        margin-left: $input-padding;
        display: block;
    }

    input[type='color'],
    input[type='tel'],
    input[type='text'],
    input[type='number'] {
        font-size: inherit;
        font-size: 1.2em;
        line-height: 2em;
        padding: 0 $input-padding;
        width: 120px;
        &.large {
            text-align: left;
            font-size: 1.5em;
            line-height: 40px;
            width: 100%;
        }
    }
    input[type='color'] {
        padding: 0;
        width: 60px;
    }
    p {
        margin: 0;
        font-size: 0.8em;
        margin-left: $input-padding;
        font-weight: 200;
    }
    .input-group {
        background-color: $color-background;
        margin-bottom: 0.5rem;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border-radius: 4px;
        input {
            flex-grow: 1;
            flex-shrink: 1;
        }
        input,
        .mat-flat-button {
            &:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .mat-flat-button,
        .mat-icon-button {
            flex-shrink: 0;
        }
        .mat-icon-button {
            &:not(:first-of-type){
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &:not(:last-child) {
                border-top-right-radius: unset;
                border-bottom-right-radius: unset;
            }
            mat-icon {
                font-size: 1.8em;
            }
        }

        &.large {
            width: 100%;
        }
    }
}
