@import "variables";


.mobile-only {
    @media #{$min-small-devices} {
        display: none !important;
    }
}
.small-devices-only {
    @media #{$min-large-devices} {
        display: none !important;
    }
}
.large-devices-only {
    @media #{$max-large-devices} {
        display: none !important;
    }
}
.desktop-only {
    @media #{$max-desktop} {
        display: none !important;
    }
}