/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'material-overrides';
@import 'colors';
@import 'responsive';
@import 'variables';
@import 'utils';
@import 'inputs';
@import 'button';

html, body {
    // font-family: 'Squada One', sans-serif;
    font-family: 'Roboto', sans-serif;
    background-color: var(--color-bg);
    touch-action: manipulation;
    color: var(--font-color);
    height: 100%; 
    height: 100%; 
    margin: 0;
}

.top-bar {
    @extend .stretch-to-edge-of-screen;
    height: $top-bar-height;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px $content-padding;
    &.fixed {
        position: fixed;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(10px);
        z-index: $z-index-nav-bar;
        top: 0px;
    }
}
.mat-button.mat-button-disabled {
    color: rgba(255, 255, 255, 0.26)!important;
}
.mat-fab.mat-button-disabled.mat-button-disabled {
    background-color: rgba(255, 255, 255, 0.096)!important;
}


.stretch-to-edge-of-screen{
    width:100%;
    @media #{$max-large-devices}{
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
}

* {
    box-sizing: border-box;
}