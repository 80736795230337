
// colors
$color-background: var(--color-bg);
$color-background-highlight: var(--color-bg-highlight);
$color-primary: var(--color-primary);
$color-primary-dark: var(--color-primary-dark);
$color-primary-light: var(--color-primary-light);
$font-color: var(--font-color);
$font-color-faded: var(--font-color-faded);
$color-error: var(--color-error);

// layout
$top-bar-height: calc(1em + 32px);
$timer-wrapper-height: 30vh;
$content-padding: 15px;
$input-padding: 10px;

// components
$input-border-radius: 4px;
$input-outline-color-error: $color-error;

// breakpoints
$breakpoint-xs: 600px;
$breakpoint-sm: 800px;
$breakpoint-lg: 1000px;
$breakpoint-xl: 1400px;

$max-mobile: "only screen and (max-width: #{$breakpoint-xs - 1})";
$max-small-devices:" only screen and (max-width: #{$breakpoint-sm - 1})";
$min-small-devices: "only screen and (min-width: #{$breakpoint-sm})";
$max-large-devices: "only screen and (max-width: #{$breakpoint-lg - 1})";
$min-large-devices: "only screen and (min-width: #{$breakpoint-lg})";
$max-desktop: "only screen and (max-width: #{$breakpoint-xl - 1})";
$min-desktop: "only screen and (min-width: #{$breakpoint-xl})";


// indexes
$z-index-modal:      10000;
$z-index-backdrop:    9999;
$z-index-nav-bar:     1000;
