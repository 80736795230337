@import 'variables';
.mat-drawer-container,
.mat-drawer {
    background-color: transparent;
    color: inherit;
}

.mat-drawer.mat-drawer-opened {
    transform: none;
}

.mat-checkbox-frame {
    border-color: $color-primary;
}

.mat-stroked-button:not(.mat-button-disabled) {
    border-color: currentColor;
}
